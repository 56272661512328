export const environment = {
    env: 'production',
    production: true,
    rollBarId: '6cc3200ad9594700b33b11d945d02914',
    supportPath: 'https://api.support.monsido.com/',
    apiEnvs: [
        {
            name: 'AU1',
            url: 'https://app1.au.monsido.com',
        },
        {
            name: 'EU1',
            url: 'https://app1.eu.monsido.com',
        },
        {
            name: 'EU2',
            url: 'https://app2.eu.monsido.com',
        },
        {
            name: 'US1',
            url: 'https://app1.us.monsido.com',
        },
        {
            name: 'US2',
            url: 'https://app2.us.monsido.com',
        },
        {
            name: 'US3 CivicPlus',
            url: 'https://app3.us.monsido.com',
        },
        {
            name: 'US4 Prospect scan',
            url: 'https://app4.us.monsido.com',
        },
        {
            name: 'MKT',
            url: 'https://app1.mkt.monsido.com',
        },
    ],
    monsidoPath: 'https://login.monsido.com/',
    oauthClientId: 'bc5e2cc965c883d4319f391a8c3036000c8ad419d908c77ad1503ae02fcf31f6',
    oauthClientSecret: '2ea854d975a1673015c0d0be91806f8a6d9d5b859740df7709aaeb54a59ea9f5',
    oauthScopes: 'reseller admin reseller_admin super_admin',
    monsidoResellers: [
        {
            name: 'Monsido Global',
            id: 1,
        },
        {
            name: 'Monsido US',
            id: 6,
        },
        {
            name: 'CivicPlus',
            id: 3,
        },

        {
            name: 'Monsido Australia',
            id: 5,
        },
        {
            name: 'Monsido UK',
            id: 101,
        },
        {
            name: 'Monsido Development',
            id: 104,
        },
    ],
};
